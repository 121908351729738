<template>
  <HomePage/>
</template>

<script>
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
/* *{
  margin: 0;
  padding: 0;
  border: 0;
} */
html{
  margin: 0;
  padding: 0;
  height:100%;
}
body{
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #f5f5f5;
  height:100%;
} 
/* html,body{
  height:100%;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
} */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height:100%;
  /* margin-top: 60px; */
}
</style>
