<template>
  <div class="homePage">

    <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-custom">
      <a class="navbar-brand title" href="#">新滤</a> <!--以后更名:闪滤-->
      <div class="ms-auto d-flex align-items-center button-container">
        <label class="btn btn-primary mb-0 me-2">
            选择文件
            <input type="file" id="fileInput" ref="fileInput" @change="onFileChange" style="display: none;" />
        </label>
        <button class="btn btn-success save_button mb-0" @click="downloadImage">保存图片</button>
      </div>
    </nav>

  <div class="container content-container">
    <div class="row">
        <!-- 过滤器部分 -->
        <div class="col-md-4 mb-4 order-2 order-md-1">
          <div class="filterLabels">
              <h2>滤镜选项</h2>
              <div class="row">
                  <!-- 在手机界面上每个滤镜选项占满宽度 -->
                  <div class="col-4 col-sm-6 col-md-4 mb-4" v-for="(filter, index) in filters" :key="index">
                      <FilterCard @click="applyFilter(filter)" :title="filter.name" />
                  </div>
              </div>
          </div>
        </div>
        <!-- 消息弹窗 -->
        
        <!-- 画布部分 -->
        <div class="col-md-8 mb-4 order-1 order-md-2">
          <div class="imgCanvas">
              <div class="img_info">
                  <p>{{filter_info}}</p>
              </div>
              <canvas class="img_canva" ref="canvas"></canvas>
          </div>
        </div>
    </div>
  </div>

</div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import axios from 'axios';
import FilterCard from '@/components/FilterCard.vue';

export default {
  components: {
      FilterCard
  },
  data() {
    return {
      filter_info:'请选择图片',
      image: null,
      filtered_img: null,
      save_img: null,
      filters: [
        { name: '原图', filter: null },
        { name: '1977', filter: '1977' },
        { name: 'Aden', filter: 'Aden' },
        { name: 'Amaro', filter: 'Amaro' },
        { name: 'Ashby', filter: 'Ashby' },
        { name: 'Brannan', filter: 'Brannan' },
        { name: 'Brooklyn', filter: 'Brooklyn' },
        { name: 'Charmes', filter: 'Charmes' },
        { name: 'Clarendon', filter: 'Clarendon' },
        { name: 'Crema', filter: 'Crema' },
        { name: 'Dogpatch', filter: 'Dogpatch' },
        { name: 'Earlybird', filter: 'Earlybird' },
        { name: 'Gingham', filter: 'Gingham' },
        { name: 'Ginza', filter: 'Ginza' },
        { name: 'Hefe', filter: 'Hefe' },
        { name: 'Helena', filter: 'Helena' },
        { name: 'Hudson', filter: 'Hudson' },
        { name: 'Inkwell', filter: 'Inkwell' },
        { name: 'Juno', filter: 'Juno' },
        { name: 'Kelvin', filter: 'Kelvin' },
        { name: 'Lark', filter: 'Lark' },
        { name: 'Lo-Fi', filter: 'Lo-Fi' },
        { name: 'Ludwig', filter: 'Ludwig' },
        { name: 'Mayfair', filter: 'Mayfair' },
        { name: 'Melvin', filter: 'Melvin' },
        { name: 'Nashville', filter: 'Nashville' },
        { name: 'Perpetua', filter: 'Perpetua' },
        { name: 'Reyes', filter: 'Reyes' },
        { name: 'Rise', filter: 'Rise' },
        { name: 'Sierra', filter: 'Sierra' },
        { name: 'Skyline', filter: 'Skyline' },
        { name: 'Slumber', filter: 'Slumber' },
        { name: 'Stinson', filter: 'Stinson' },
        { name: 'Sutro', filter: 'Sutro' },
        { name: 'Toaster', filter: 'Toaster' },
        { name: 'Valencia', filter: 'Valencia' },
        { name: 'Vesper', filter: 'Vesper' },
        { name: 'Walden', filter: 'Walden' },
        { name: 'Willow', filter: 'Willow' },
        { name: 'X-ProII', filter: 'X-ProII' }
      ],
      selectedFilter: null,
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      // 定义文件大小限制（以字节为单位，这里限制为1MB）
      const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB
      // 检查文件是否存在
      if (!file) {
          alert('请先选择一个文件');
          return;
      }
      // 检查文件大小
      if (file.size > MAX_FILE_SIZE) {
          alert('文件大小不能超过 1MB');
          return;
      }
      this.image = file
      this.applyFilter(null);
    },
    applyFilter(filter) {
      try {
        this.selectedFilter = filter;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0);
          if (filter) {
            // apply filter here
            context.filter = filter;
            context.drawImage(image, 0, 0);
            context.filter = 'none';
            this.filter_info = '处理中';
            this.uploadImage(filter);
          }
        };
        image.src = URL.createObjectURL(this.image); 
      } catch (error) {
        alert("处理失败");
      }
    },
    uploadImage(filter) {
      const canvas = this.$refs.canvas;
      canvas.toBlob((blob) => {
        // const formData = new FormData();
        // formData.append('image', blob, this.image.name);
        const formData = new FormData();
        formData.append('filtername', this.selectedFilter.filter);
        // formData.append('img', this.image);
        formData.append('img', blob, this.image.name);
        axios.post('http://sparklefilter.top:8000/filter/', formData,  { timeout: 50000 }).then((response) => {
          // 处理服务器响应
          this.filtered_img = response.data.pic;
          this.show_filtered_img()
          this.filter_info = '处理完成，滤镜：' + filter.name
        }).catch(() => {
          // 处理错误
          this.filter_info = '处理失败，请重试';
        });
      }, 'image/jpeg', 0.9);
    },
    show_filtered_img(){
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
        context.drawImage(image, 0, 0);
      };
      if (this.filtered_img != null ){
        image.src = this.filtered_img
      }
    },
    base64ToBlob(base64, mime) {
      const byteCharacters = atob(base64);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: mime });
    },

    downloadImage() {
      if (this.filtered_img != null){
        try {
          const mime = 'image/png'; 
          const base64 = this.filtered_img.split(',')[1];
          const blob = this.base64ToBlob(base64, mime);
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'image.png';
          link.click();
          URL.revokeObjectURL(url); 
        } catch (error) {
          // 弹窗显示失败
          alert('保存图片失败');
        }
      }else{
        alert('当前无图片');
      }
    },
  },
};
</script>

  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.homePage{
  height: 100%;
  margin: 0;
  padding: 0;
}
.navbar-custom {
  padding: 10px;
}
.navbar-brand {
  color: black; /* 设置品牌文字颜色为黑色 */
}
.navbar-brand:hover {
  color: #333; /* 鼠标悬停时颜色稍微浅一点 */
}
.filterLabels h2, .imgCanvas h2 {
  margin-bottom: 1rem;
}
.img_info {
  margin-bottom: 1rem;
}
.img_canva {
  width: 100%;
  height: auto;
}
.content-container {
  padding: 15px;
}
.foodprint {
  text-align: center;
  padding: 10px;
  font-size: 0.875rem;
  color: #6c757d;
}

/* 确保在小屏幕上每个 filter-card 占满整个宽度 */
.filterLabels .col-12 {
    flex: 1 1 100%; /* 在手机屏幕上，占满整行 */
    max-width: 100%; /* 确保不会溢出 */
}
/* 在更大的屏幕上调整布局 */
@media (min-width: 576px) {
    .filterLabels .col-sm-6 {
        flex: 1 1 50%; /* 在小屏幕上每行显示两个 filter-card */
    }
}
@media (min-width: 768px) {
    .filterLabels .col-md-4 {
        flex: 1 1 33.333%; /* 在中屏幕上每行显示三个 filter-card */
    }
}


</style>
